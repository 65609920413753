<template>
  <Swiper
    :breakpoints="breakpoints"
    :mousewheel="{forceToAxis: true}"
    :modules="[SwiperMousewheel]"
    @swiper="setControlledSwiper"
  >
    <slot />
    <template #container-end>
      <SlidersPaginationProgress
        v-if="control"
        class="pt-5 lg:hidden"
        :page="control?.activeIndex"
        :size="control.slides.length"
      />
      <div
        v-if="!hideControl"
        class="relative top-6 z-10 flex gap-5 max-lg:hidden"
      >
        <button
          class="cursor-pointer"
          @click="control.slidePrev()"
        >
          <IconsActionsChevron
            class="rotate-90"
            height="20"
          />
        </button>
        <button
          class="cursor-pointer"
          @click="control.slideNext()"
        >
          <IconsActionsChevron
            class="-rotate-90"
            height="20"
          />
        </button>
      </div>
    </template>
  </Swiper>
</template>

<script setup lang="ts">
import {Swiper as SwiperClass} from 'swiper';

const props = defineProps<{
  hideControl?: boolean
  mobileBreakpoints?: boolean
  startWith?: number
}>();

const control = ref<SwiperClass | null>(null);
const setControlledSwiper = (sw: SwiperClass) => control.value = sw;

const breakpoints = computed(() => ({
  0: {slidesPerView: props.startWith ?? 'auto', spaceBetween: 24, slidesOffsetBefore: 0},
  640: props.mobileBreakpoints && {slidesPerView: 1.5, spaceBetween: 24, slidesOffsetBefore: 0},
  768: props.mobileBreakpoints && {slidesPerView: 2, spaceBetween: 24, slidesOffsetBefore: 0},
  1024: {slidesPerView: 2.5, spaceBetween: 24, slidesOffsetBefore: !props.hideControl && 120},
  1280: {slidesPerView: 3, spaceBetween: 24, slidesOffsetBefore: !props.hideControl && 120},
  1420: {slidesPerView: 3.5, spaceBetween: 24, slidesOffsetBefore: !props.hideControl && 120},
  1536: {slidesPerView: 3.8, spaceBetween: 24, slidesOffsetBefore: !props.hideControl && 120},
}));
</script>
