<template>
  <div class="flex select-none flex-row items-center gap-4">
    <span class="whitespace-nowrap text-sm">{{ pageIndexing }}</span>
    <div class="h-1 w-full rounded-full bg-white">
      <div
        :style="{width: barWidth}"
        class="bg-green h-full rounded-full transition-all duration-700"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  size: number;
  page: number;
}>();

const pageIndexing = computed(() => `${props.page + 1} / ${props.size}`);
const barWidth = computed(() => `${((props.page + 1) / props.size) * 100}%`);
</script>
